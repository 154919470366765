import React from "react"
import Avatar from "../Avatar"
import TypeWriter from "../TypeWriter"
import SocialComponent, { SocialType } from "../Socials"
import styled from "@emotion/styled"
import { centerFlexRow } from "../../helpers"
import { Link } from "gatsby"

const Page = styled.div`
  display: grid;
  width: 100%;
  place-items: center;
  font-size: 0.8 rem;
  color: black;


`
const AvatarContainer = styled.div`
  @media (min-width: 480px) {
    width: 400px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`

const Footer = styled.footer`
  height: 120px;
  width: 100%;
`

const Social = styled(SocialComponent)`
  margin: 0.5rem;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  border: ${(props:any) => props.color} solid 1px;
  background: white;
  color: ${(props:any) => props.color};
  &:hover {
    color: white;
    background: ${(props:any) => props.color};
  }
  ${centerFlexRow}
  transition: all .4s;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Resume = styled(Link)`
  padding: 5px 25px;
  @media (max-width: 480px) {
    padding: 5px 10px;
  }
  text-decoration: none;
  color: rgb(59, 130, 246);
  border: 2px solid rgb(59, 130, 246);
  font-weight: 600;
  font-size: 0.75rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    color: white;
    background: rgb(59, 130, 246);
    opacity: 0.8;
    box-shadow: 0 14px 26px -12px rgba(131, 0, 255, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(131, 0, 255, 0.2);
  }
`
type TypeWriterType = {
  texts: string[]
  delay: number
  loop: boolean
  typePause: number
  deletePause: number
  loopPause: number
}
type SocialDataType = {
  icon: string
  name: string
  url: string
  value: string
  color: string
}
type HomeProps = {
  TypewriterObj: TypeWriterType
  SocialData: SocialDataType[]
}

const Home: React.FC<HomeProps> = ({
  TypewriterObj: { texts, loop, loopPause, delay, typePause, deletePause },
  SocialData,
}:any) => {
  return (
    <div className="min-h-screen">
      <Page className="font-homeFont">
        <Wrapper>
          <AvatarContainer className="px-4 mt-12 sm:px-10">
            <Avatar className="text-black " />
          </AvatarContainer>
          <TypeWriter
          className="pt-8 text-black "
            texts={texts}
            loop={loop}
            loopPause={loopPause}
            delay={delay}
            typePause={typePause}
            deletePause={deletePause}
          ></TypeWriter>
          <div className="flex flex-row flex-wrap justify-center pt-8 mt-1 space-x-6 align-center">
          <Resume to="/Resume">My Résumé</Resume>
          <Resume to="/blog">To My Blog</Resume>
          <Resume to="/FunStuff">To fun stuff</Resume>
          </div>
        </Wrapper>
      </Page>

      <Footer className="flex flex-row justify-center w-full mt-10 ">
        <div className="flex flex-row flex-wrap justify-center w-3/4">
        {SocialData.map(
          (item: SocialType): React.ReactElement<SocialType> => (
            <Social key={item.name} {...item} />
          )
        )}
        </div>
      </Footer>
    </div>
  )
}

export default Home

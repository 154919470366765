import React from "react"
import styled from "@emotion/styled"
import Image from "../HomeImage"

const Wrapper = styled.div`
  border: 0.001rem solid #94b1e0;
  border-radius: 50%;
  box-shadow: 0px 0px 30px 4px #94b1e0;
  background-color: #f0efeb;
  overflow: hidden;
  width: 100%;
  height: 100%;
`
type AvatarType = {
  className?: string
}
const Avatar: React.FC<AvatarType> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Image />
    </Wrapper>
  )
}

export default Avatar

import { css } from "@emotion/react"

export const centerFlexRow = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const centerFlexColumn = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

import React from "react"
import styled from "@emotion/styled"
// @ts-ignore
import Typewriter from "typewriter-effect"

const Type = styled.div`
  display: flex;
  font-size: 1.7rem;
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
  text-align: center;
`

type TypeProps = {
  texts: Array<string>
  delay: number
  loop: boolean
  typePause: number
  deletePause: number
  loopPause: number
  className?: string
}

const TypeWiter: React.FC<TypeProps> = ({
  texts,
  delay,
  loop,
  typePause,
  deletePause,
  loopPause,
  className,
}) => {
  return (
    <Type className={className}>
      <Typewriter
        options={{
          loop: loop,
        }}
        onInit={(typewriter: any) => {
          typewriter.changeDelay(delay + 10).changeDeleteSpeed(delay)
          for (const item of texts) {
            typewriter
              .typeString(item)
              .pauseFor(typePause)
              .deleteChars(item.length)
              .pauseFor(deletePause)
          }
          typewriter.deleteAll().pauseFor(loopPause).start()
        }}
      />
    </Type>
  )
}

export default TypeWiter

import React from "react"
import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as faIcons from "@fortawesome/free-brands-svg-icons"

const Wrapper = styled.a``

export type SocialType = {
  icon: string
  name: string
  url: string
  color: string
  className?: string
}

const Component: React.FC<SocialType> = ({ icon, url, className }) => {
  return (
    <Wrapper href={url} className={className}>
      {/*@ts-ignore*/}
      <FontAwesomeIcon icon={faIcons[icon]} />
    </Wrapper>
  )
}

export default Component

import React from "react"
import Home from "../components/Home"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import TypewriterObj from "../data/typeWriterData.json"
import SocialData from "../data/socialData.json"

const HomePage: React.FC<void> = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Home TypewriterObj={TypewriterObj} SocialData={SocialData} />
    </Layout>
  )
}

export default HomePage
